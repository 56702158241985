
import { reactive, shallowRef } from '@vue/reactivity'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Pic from '@/components/Pic.vue'
import { readRmInfo, readGiftList, readInputInfo } from '@/events/christmas/christmas.api'
import router from '@/router'

export default {
  name: 'Christmas',
  components: { PageWithHeader, Pic },
  setup () {
    const data = reactive({
      rechargeAmount: 0,
      mtTradeAmount: 0,
    })
    const isWin = shallowRef(false)
    const winGift = shallowRef('')
    const isInputAdd = shallowRef(false)
    const isDeliver = shallowRef(false)
    const inputAddInfo = shallowRef()
    const giftList = shallowRef()

    // 获取礼物列表
    const getGiftList = () => {
      readGiftList().then(res => {
        res.forEach(item => {
          if (item.chooseStatus === 1) {
            isWin.value = true
            winGift.value = item.giftName
          }
        })
        giftList.value = res
      })
    }
    getGiftList()
    // 获取用户填写的地址信息
    const getUserAdd = () => {
      readInputInfo().then(res => {
        if (res.recipientName && res.recipientMobile && res.recipientAddress) {
          isInputAdd.value = true
          inputAddInfo.value = res
        }
        if (res.giftStatus >= 2) {
          isDeliver.value = true
        }
      })
    }
    getUserAdd()
    const linkToEditAddress = () => {
      router.push('/address')
    }
    // 查询充值记录
    const getInfo = () => {
      readRmInfo().then(res => {
        data.rechargeAmount = res.rechargeAmount
        data.mtTradeAmount = res.mtTradeAmount
      })
    }
    getInfo()
    return {
      isWin,
      isInputAdd,
      isDeliver,
      inputAddInfo,
      data,
      giftList,
      winGift,
      linkToEditAddress,
    }
  },
}
